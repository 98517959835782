"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.SpacesGalleries = function SpacesGalleries() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $spaces = [].slice.call(document.querySelectorAll('.space-gallery'));
    if ($spaces) {
      $spaces.forEach(function ($space) {
        var $images = [].slice.call($space.querySelectorAll('img'));
        if ($images) {
          var flkty = new Flickity($space, {
            // options
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            imagesLoaded: true,
            wrapAround: true,
            lazyLoad: true
          });
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();