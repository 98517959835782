"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.SmoothScrolling = function SmoothScrolling() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    (function () {
      'use strict';

      // Feature Test
      if ('querySelector' in document && 'addEventListener' in window && Array.prototype.forEach) {
        // Function to animate the scroll
        var smoothScroll = function smoothScroll(anchor, duration) {
          // Calculate how far and how fast to scroll
          var startLocation = window.pageYOffset;
          var endLocation = anchor.offsetTop;
          var distance = endLocation - startLocation;
          var increments = distance / (duration / 16);
          var stopAnimation;
          // Scroll the page by an increment, and check if it's time to stop
          var animateScroll = function animateScroll() {
            window.scrollBy(0, increments);
            stopAnimation();
          };
          // If scrolling down
          if (increments >= 0) {
            // Stop animation when you reach the anchor OR the bottom of the page
            stopAnimation = function stopAnimation() {
              var travelled = window.pageYOffset;
              if (travelled >= endLocation - increments || window.innerHeight + travelled >= document.body.offsetHeight) {
                clearInterval(runAnimation);
              }
            };
          }
          // Loop the animation function
          var runAnimation = setInterval(animateScroll, 16);
        };
        // Define smooth scroll links
        var scrollToggle = document.querySelectorAll('.scroll');
        // For each smooth scroll link
        [].forEach.call(scrollToggle, function (toggle) {
          // When the smooth scroll link is clicked
          toggle.addEventListener('click', function (e) {
            // Prevent the default link behavior
            e.preventDefault();
            // Get anchor link and calculate distance from the top
            var dataID = toggle.getAttribute('href');
            var dataTarget = document.querySelector(dataID);
            var dataSpeed = toggle.getAttribute('data-speed');
            // If the anchor exists
            if (dataTarget) {
              // Scroll to the anchor
              smoothScroll(dataTarget, dataSpeed || 500);
            }
          }, false);
        });
      }
    })();
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();