"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.SpacesOffice = function SpacesOffice() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $spaces = [].slice.call(document.querySelectorAll('.space-info .site-btn'));
    if ($spaces) {
      $spaces.forEach(function ($space) {
        var $link = $space.href;
        var $newLink = '';
        if ($link) {
          switch ($link.substr($link.lastIndexOf('/') + 1)) {
            case 'montreal':
              $newLink = $link + '?office=montreal';
              break;
            // case 'longueuil':
            //   $newLink = $link + '?office=rive-sud';
            // break;
            default:
              $newLink = $link + '?office=montreal';
          }
          $space.href = $newLink;
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();