"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.AboutPage = function AboutPage() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $switch = document.querySelector('.categories-radios');
    if ($switch) {
      var $radios = [].slice.call($switch.querySelectorAll('input[type=radio]'));
      if ($radios) {
        $radios.forEach(function ($radio) {
          $radio.addEventListener('change', function (e) {
            var value = $radio.value;
            var $partnersSections = [].slice.call(document.querySelectorAll('.partner-section'));
            $partnersSections.forEach(function ($partnersSection) {
              $partnersSection.classList.remove('is-active');
            });
            var $selectedCategory = document.querySelector('.partner-section[data-id="' + value + '"]');
            $selectedCategory.classList.add('is-active');
          });
        });
      }
    }
    var $loadmoreMembers = document.querySelector('#pagination-members');
    if ($loadmoreMembers) {
      $loadmoreMembers.addEventListener("click", loadMoreMembers);
    }
    return true;
  };
  var loadMoreMembers = function loadMoreMembers(e) {
    var target = e.target || e.srcElement;
    var $hiddenMembers = [].slice.call(document.querySelectorAll('#membres-entrepreneurs .member.is-hidden'));
    if ($hiddenMembers) {
      if ($hiddenMembers.length < 7) {
        target.classList.add('is-hidden');
      } else {
        target.classList.remove('is-hidden');
      }
      $hiddenMembers.forEach(function (element, index) {
        if (index < 6) {
          element.classList.toggle('is-hidden');
        }
      });
    }
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();