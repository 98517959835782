"use strict";

window.site = window.site || {};

/**
 * Init Mobile Menu related scripts
 * @class Site
 * @static
 */
site.sidePanel = function sidePanel() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $switch = document.querySelectorAll('#sidepanel');
  var sidePanelClose = document.querySelectorAll('[data-close-sidepanel]');
  var sidePanelOverlay = document.querySelector('.sidepanel-overlay');
  var sidePanel = document.querySelector('.sidepanel');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    $switch.addEventListener("click", function (e) {
      e.preventDefault();
      sidePanel.classList.add('opened');
      sidePanelOverlay.classList.add('opened');
    });
    sidePanelClose.addEventListener("click", function (el) {
      sidePanel.classList.remove('opened');
      sidePanelOverlay.classList.remove('opened');
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();